import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Apis } from "../../../config";

const fetchCommunityAdminList = createAsyncThunk('community/fetchCommunityAdminList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityMemberList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchCommunityMentorList = createAsyncThunk('community/fetchCommunityMentorList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityMemberList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchCommunityMemberList = createAsyncThunk('community/fetchCommunityMemberList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityMemberList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

const fetchMoreCommunityMemberList = createAsyncThunk('community/fetchMoreCommunityMemberList', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.get(Apis.GetCommunityMemberList(data));
        return response?.data;
    } catch (err) {
        return rejectWithValue(err?.message || 'Failed to fetch post');
    }
});

export const communityAdminMentorMemberThunk = { 
    fetchCommunityAdminList,
    fetchCommunityMemberList,
    fetchCommunityMentorList,
    fetchMoreCommunityMemberList
};

