import { createSelector, createSlice } from "@reduxjs/toolkit";
import { communityAdminMentorMemberThunk } from "./communityAdminMentorMemberThunkAction";

const initialState = {
    communityAdminData: {},
    communityMentorData: {},
    communityMemberData: {},
    status: 'idle',
    error: null
}

/* Community Admin Mentor Member Slice configuration */
const communityAdminMentorMemberSlice = createSlice({
    name: 'communityAdminMentorMemberData',
    initialState,
    reducers: {
        resetCommunityAdmionMentorMemberData: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(communityAdminMentorMemberThunk.fetchCommunityAdminList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityAdminList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newData = action.payload || {};
                state.communityAdminData = newData;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityAdminList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMentorList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMentorList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newData = action.payload || {};
                state.communityMentorData = newData;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMentorList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMemberList.pending, (state, action) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMemberList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newData = action.payload || {};
                state.communityMemberData = newData;
            })
            .addCase(communityAdminMentorMemberThunk.fetchCommunityMemberList.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.payload;
            })
            .addCase(communityAdminMentorMemberThunk.fetchMoreCommunityMemberList.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(communityAdminMentorMemberThunk.fetchMoreCommunityMemberList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const newMemberData = action.payload?.body;
                const newCommunityMemberData = action.payload?.body?.content || [];
                state.communityMemberData.body = { ...newMemberData, content: [...state.communityMemberData?.body?.content, ...newCommunityMemberData] };
            
            })
            .addCase(communityAdminMentorMemberThunk.fetchMoreCommunityMemberList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
})

export const getCommunityMemberListSelector = createSelector((state) => state?.communityMemberData, (communityMemberData) => communityMemberData);
export const { resetCommunityAdmionMentorMemberData } = communityAdminMentorMemberSlice.actions;
export default communityAdminMentorMemberSlice.reducer;