const individual = "INDIVIDUAL";
const institute = "INSTITUTE";
const faculty = "FACULTY";
const admin = "ADMIN";
const candidate = "CANDIDATE";
const pdfIcon = "https://cdn.wseinfratech.com/pdf_icon.svg";
const wordIcon = "https://cdn.wseinfratech.com/word_icon.svg";
const defaultProfileLogo = "https://cdn.newsepick.com/avatarBlankNew.png";
const defaultProductLogo = "https://cdn.newsepick.com/logoBrand-r.png";
const defaultProductLogoMobile = "https://cdn.newsepick.com/mobile-logo.png";
const defaultZeroDataImage = "https://cdn.newsepick.com/zero_data_community.png";
const comingSoonImage = "https://cdn.newsepick.com/cooming-soon-analytics.png";
const globalSearchZeroDataPngImage = "https://cdn.newsepick.com/search-zerodata.png";
const globalSearchZeroDataWebpImage = "https://cdn.newsepick.com/search-zerodata.webp";
const communityMemberDefaultInstituteId= "663a15c401b63324af284d36";

export const constants = {
    individual, 
    institute, 
    faculty, 
    admin, 
    candidate, 
    pdfIcon,
    wordIcon, 
    defaultProfileLogo, 
    defaultProductLogo, 
    defaultProductLogoMobile, 
    defaultZeroDataImage,
    comingSoonImage,
    globalSearchZeroDataPngImage,
    globalSearchZeroDataWebpImage,
    communityMemberDefaultInstituteId
}